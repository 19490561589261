<template>
  <div>
    <b-overlay :show="isLoading">
      <b-table
        id="user-permission-table"
        :items="users"
        :fields="fields"
        responsive
        selectable
        show-empty
        @row-clicked="row => showDetails(row)"
      >
        <!-- User Name -->
        <template #cell(name)="row">
          <div class="d-flex justify-content-between">
            <div
              class="col-260"
              @click="currentPermissionShown = null"
            >
              <b-avatar
                :src="getAvatar(row.item.avatar)"
                :text="getInitials(row.item.name)"
                :variant="getRandomVariant()"
                badge
                :badge-variant="row.item.isOnline ? 'success' : 'secondary'"
                class="mr-1"
              />
              {{ row.item.name }}
              <span v-if="row.item.permissions.total">
                <feather-icon
                  v-if="row.detailsShowing"
                  size="20"
                  icon="ChevronUpIcon"
                />
                <feather-icon
                  v-else
                  size="20"
                  icon="ChevronDownIcon"
                />
              </span>
            </div>
          </div>
        </template>

        <!-- Role in Company -->
        <template #cell(companyRole)="row">
          <div class="col-240">
            <b-avatar
              class="mr-1"
            >
              <feather-icon
                icon="BriefcaseIcon"
                size="20"
              />
            </b-avatar>
            {{ row.item.companyRoles.data[0].role.displayName }}
          </div>
        </template>

        <!-- Role in Project -->
        <template #cell(projectRole)="row">
          <div class="col-240">
            <b-avatar
              class="mr-1"
            >
              <feather-icon
                icon="UserIcon"
                size="20"
              />
            </b-avatar>
            {{ row.item.projectRoles.data[0].role.displayName }}
          </div>
        </template>

        <!-- Number of Permissions Assigned -->
        <template #cell(assignedPermissions)="row">
          <div class="mr-2">
            {{ row.item.permissions.total }}
          </div>
        </template>

        <!-- Accordion Details -->
        <template #row-details="row">
          <b-card class="ml-2">
            <div
              v-for="level in permissionLevels"
              :key="level"
            >
              <!-- Permission Heading -->
              <h4
                v-if="row.item.groupByPermissions[level]"
                v-b-toggle="`permission-${level}`"
                class="mb-1"
                @click="currentPermissionShown !== level ? currentPermissionShown = level : currentPermissionShown = null"
              >
                <feather-icon
                  :icon="currentPermissionShown === level ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  size="20"
                />
                {{ getPermissionLevel(row.item.groupByPermissions[level][0].permissionLevel, row.item) }}
                <!-- Add New Permission -->
                <b-avatar
                  v-if="canManagePermissions && (level === 'USERPROJECT' || level === 'USERCOMPANY')"
                  v-b-tooltip.hover
                  title="Add Permission"
                  variant="success"
                  button
                  class="cursor-pointer mx-1"
                  @click="showModal(row.item, null, level)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                  />
                </b-avatar>
              </h4>
              <b-collapse
                :id="`permission-${level}`"
                accordion="permission"
                class="pl-2"
              >
                <b-row>
                  <!-- Each Permission -->
                  <b-col
                    v-for="permission, index in row.item.groupByPermissions[level]"
                    :key="index"
                    class="user-level-permission mb-2"
                    md="4"
                  >
                    <b-row>
                      <b-col md="8">
                        <h5 :class="permission.allow ? 'text-success' : 'text-danger'">
                          {{ permission.slug }}
                          <feather-icon
                            :icon="permission.allow ? 'CheckIcon' : 'XIcon'"
                            size="20"
                            :color="permission.allow ? '#22aa5f' : '#e63333'"
                          />
                        </h5>
                        <p class="mb-0">
                          {{ permission.description }}
                        </p>
                      </b-col>
                      <b-col
                        v-if="canManagePermissions && (level === 'USERPROJECT' || level === 'USERCOMPANY')"
                        md="4"
                        class="px-0"
                      >
                        <!-- Edit Action -->
                        <b-avatar
                          v-b-tooltip.hover
                          title="Edit Permission"
                          variant="warning"
                          button
                          class="user-permission-action cursor-pointer ml-1"
                          @click="showModal(row.item, permission)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            size="16"
                          />
                        </b-avatar>

                        <!-- Delete Action -->
                        <b-avatar
                          v-if="permission.permissionLevel === 'USERPROJECT' || permission.permissionLevel === 'USERCOMPANY'"
                          v-b-tooltip.hover
                          title="Delete Permission"
                          variant="danger"
                          button
                          class="user-permission-action cursor-pointer mx-1"
                          @click="showDeleteModal(row.item.uuid, permission)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="16"
                          />
                        </b-avatar>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
              <hr v-if="row.item.groupByPermissions[level]">
            </div>
          </b-card>
        </template>
      </b-table>

      <b-pagination
        v-if="totalUsers > perPage"
        v-model="currentPage"
        class="float-right pb-2"
        :per-page="perPage"
        :total-rows="totalUsers"
        :disabled="isLoading"
        aria-controls="user-permission-table"
        @input="() => { isLoading = true; fetchUsers() }"
      />
    </b-overlay>

    <create-edit-permissions
      modal="user-permission"
      :user="selectedUser"
      :permission="selectedPermission"
      :permission-list="permissionList"
      :permission-info="permissionInfo"
      @updated="fetchUsers"
      @close="() => clearSelected"
    />

    <!-- Delete Modal -->
    <b-modal
      id="user-permission-delete-modal"
      title="Revoke Permission"
      ok-title="Revoke"
      ok-variant="danger"
      @ok="deletePermission"
    >
      Are you sure you want to revoke <span class="font-weight-bold">{{ permissionToDelete.slug }}</span> permission?
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BOverlay, BPagination, BRow, BCol, BAvatar, BCard, BModal, BCollapse, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import _ from 'lodash'
import CreateEditPermissions from './CreateEditPermissions.vue'

export default {
  components: {
    BTable,
    BOverlay,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BModal,
    BCollapse,
    CreateEditPermissions,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    searchTerm: {
      type: String,
      default: () => '',
    },
    canManagePermissions: {
      type: Boolean,
      deafult: () => false,
    },
  },
  data() {
    return {
      currentPermissionShown: null,
      users: [],
      fields: [
        { key: 'name', label: this.$t('Name'), thClass: 'text-capitalize' },
        { key: 'companyRole', label: this.$t('Role in Company'), thClass: 'text-capitalize' },
        { key: 'projectRole', label: this.$t('Role in Project'), thClass: 'text-capitalize' },
        { key: 'assignedPermissions', label: this.$t('Assigned Permissions'), thClass: 'text-capitalize' },
      ],
      totalUsers: 0,
      currentPage: 1,
      perPage: 10,
      permissionToDelete: {},
      isLoading: true,
      permissionLevels: [
        'USERPROJECT', 'USERCOMPANY', 'PROJECTROLE', 'COMPANYROLE', 'GLOBALPROJECTROLE', 'GLOBALCOMPANYROLE',
      ],
      permissionList: [],
      permissionInfo: null,
      selectedPermission: null,
      selectedUser: null,
    }
  },
  watch: {
    searchTerm: {
      handler(val) {
        if (val || val === '') this.fetchUsers(val)
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    showDetails(row) {
      if (row.permissions.total) {
        // eslint-disable-next-line no-underscore-dangle
        if (row._showDetails) {
          this.$set(row, '_showDetails', false)
        } else {
          this.users.forEach(item => {
            this.$set(item, '_showDetails', false)
          })

          this.$nextTick(() => {
            this.$set(row, '_showDetails', true)
          })
        }
      }
    },
    fetchUsers: _.debounce(function () {
      this.isLoading = true
      useApollo.users.searchUserWithPermissions({
        keyword: this.searchTerm,
        companyUid: this.$store.state.project.selectedCompany,
        projectUid: this.$store.state.project.selectedProject,
        first: this.perPage,
        page: this.currentPage,
      }).then(response => {
        this.users = response.data.users.data.map(user => ({
          ...user,
        }))
        this.totalUsers = response.data.users.total
        const options = this.users.map(user => ({
          label: user.name,
          value: user.uuid,
          isOnline: user.isOnline,
        }))
        this.users.forEach((user, index) => {
          const permissions = this.GROUP_BY(user.permissions.data, 'permissionLevel')
          this.users[index].groupByPermissions = permissions
        })
        this.$emit('result', options)
      }).finally(() => { this.isLoading = false })
    }, 1000),
    getPermissions() {
      if (this.permissionList.length) return
      useApollo.project.getPermissions().then(response => {
        this.permissionList = response.data.permissions.data.map(permission => ({
          label: permission.description,
          value: permission.slug,
        }))
      })
    },
    showDeleteModal(uuid, permission) {
      this.permissionToDelete = permission
      this.permissionToDelete.uuid = uuid
      this.$bvModal.show('user-permission-delete-modal')
    },
    showModal(user, permission = {}, level = null) {
      this.getPermissions()
      this.selectedUser = {
        uuid: user.uuid,
        name: user.name,
        permission: permission?.slug ?? null,
        permissionLevel: level ?? permission?.permissionLevel,
        allow: permission?.allow,
      }
      this.permissionInfo = permission
      this.$bvModal.show('create-edit-user-permission')
    },
    deletePermission() {
      const data = {
        // eslint-disable-next-line no-underscore-dangle
        projectUid: this.$store.state.project.selectedProject,
        companyUid: this.$store.state.project.selectedCompany,
        slug: this.permissionToDelete.slug,
        input: {
          action: 'DELETE',
          userUid: this.permissionToDelete.uuid,
        },
      }

      // eslint-disable-next-line no-underscore-dangle
      if (this.permissionToDelete.permissionLevel.toUpperCase() === 'USERPROJECT') {
        useApollo.project.updateProjectPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateProjectPermission.message,
            },
          })
        }).finally(() => { this.fetchUsers() })
      // eslint-disable-next-line no-underscore-dangle
      } else if (this.permissionToDelete.permissionLevel.toUpperCase() === 'USERCOMPANY') {
        useApollo.project.updateCompanyPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateCompanyPermission.message,
            },
          })
        }).finally(() => { this.fetchUsers() })
      }
    },
    getPermissionLevel(level, user) {
      switch (level) {
        case 'USERPROJECT':
          return `Permissions to ${user.name} in ${this.$store.state.project.project.name}`
        case 'USERCOMPANY':
          return `Permissions to ${user.name} in ${this.$store.state.project.company.name}`
        case 'PROJECTROLE':
          return `Permissions to ${user.projectRoles.data[0].role.displayName} in ${this.$store.state.project.project.name}`
        case 'COMPANYROLE':
          return `Permissions to ${user.companyRoles.data[0].role.displayName} in ${this.$store.state.project.company.name}`
        case 'GLOBALPROJECTROLE':
          return `Permissions to ${user.projectRoles.data[0].role.displayName}`
        case 'GLOBALCOMPANYROLE':
          return `Permissions to ${user.companyRoles.data[0].role.displayName}`
        default:
          return ''
      }
    },
    clearSelected() {
      this.selectedPermission = null
      this.selectedUser = null
    },
  },
}
</script>

<style lang="scss" scoped>
.user-permission-action {
  opacity: 0;
}
.user-level-permission:hover {
  .user-permission-action {
    opacity: 1;
  }
}
.col-260 {
  min-width: 260px;
}
@media screen and (max-width: 768px) {
  .user-permission-action {
    opacity: 1;
  }
}
</style>

<style lang="scss">
#user-permission-table {
  .table.b-table > tbody .b-table-row-selected.table-active td,
  .table.b-table > tbody > .table-active,
  .table.b-table > tbody > .table-active > th,
  .table.b-table > tbody > .table-active > td,
  .table-active,
  .table-active > th,
  .table-active > td{
    background-color: transparent !important;
  }
}
</style>
