<template>
  <div>
    <b-overlay :show="isLoading">
      <b-table
        id="permission-table"
        :fields="fields"
        :items="permissions.data"
        show-empty
        responsive
      >
        <template #cell(slug)="data">
          <p class="mb-0">
            {{ data.item.description }}
          </p>
          <h5>{{ data.item.slug }}</h5>
        </template>
        <template #cell(globalPermissions)="data">
          <div v-if="data.item.globalPermissions.length">
            <div
              v-for="permission, index in data.item.globalPermissions"
              :key="index"
              class="my-1 col-240"
            >
              <b-avatar
                class="mr-1"
                :variant="permission.allow ? 'success' : 'danger'"
              >
                <feather-icon
                  :icon="permission.permissible.__typename === 'UserRole' ? 'BriefcaseIcon' : 'UserIcon'"
                  size="20"
                />
              </b-avatar>
              {{ permission.permissible.displayName }}
              <feather-icon
                class="ml-1"
                :icon="permission.allow ? 'CheckIcon' : 'XIcon'"
                size="20"
                :color="permission.allow ? '#22aa5f' : '#e63333'"
              />
            </div>
          </div>
          <span v-else>-</span>
        </template>
        <template
          #cell(localPermissions)="data"
        >
          <div v-if="data.item.localPermissions.length">
            <div
              v-for="permission, index in data.item.localPermissions"
              :key="index"
              class="my-1 d-flex local-permission"
            >
              <div
                v-if="permission.permissible.__typename === 'UserRole'"
              >
                <div class="d-flex col-240">
                  <b-avatar :variant="permission.allow ? 'success' : 'danger'">
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="d-flex">
                      {{ permission.permissible.displayName }}
                      <feather-icon
                        class="ml-1"
                        :icon="permission.allow ? 'CheckIcon' : 'XIcon'"
                        size="20"
                        :color="permission.allow ? '#22aa5f' : '#e63333'"
                      />
                    </div>
                    <p class="small mb-0">
                      {{ `${permission.permitted.__typename}: ${permission.permitted.name}` }}
                    </p>
                  </div>

                </div>
              </div>

              <div
                v-else-if="permission.permissible.__typename === 'ProjectRole'"
              >
                <div class="d-flex col-240">
                  <b-avatar :variant="permission.allow ? 'success' : 'danger'">
                    <feather-icon
                      icon="UserIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="d-flex">
                      {{ permission.permissible.displayName }}
                      <feather-icon
                        class="ml-1"
                        :icon="permission.allow ? 'CheckIcon' : 'XIcon'"
                        size="20"
                        :color="permission.allow ? '#22aa5f' : '#e63333'"
                      />
                    </div>
                    <p class="small mb-0">
                      {{ `${permission.permitted.__typename}: ${permission.permitted.name}` }}
                    </p>
                  </div>

                </div>
              </div>
              <div
                v-else-if="permission.permissible.__typename === 'User'"
              >
                <div class="d-flex col-240">
                  <b-avatar
                    :variant="permission.allow ? 'success' : 'danger'"
                    :text="getInitials(permission.permissible.name)"
                  />
                  <div class="ml-1">
                    <div class="d-flex">
                      {{ permission.permissible.name }}
                      <feather-icon
                        class="ml-1"
                        :icon="permission.allow ? 'CheckIcon' : 'XIcon'"
                        size="20"
                        :color="permission.allow ? '#22aa5f' : '#e63333'"
                      />
                    </div>
                    <p class="small mb-0">
                      {{ `${permission.permitted.__typename}: ${permission.permitted.name}` }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- Edit Local Permission -->
              <div v-if="canManagePermissions">
                <b-avatar
                  v-b-tooltip.hover
                  title="Edit Permission"
                  variant="warning"
                  button
                  class="local-permission-actions cursor-pointer ml-1 mb-1"
                  @click="showModal(data.item, permission)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </b-avatar>

                <!-- Delete Local Permission -->
                <b-avatar
                  v-b-tooltip.hover
                  title="Delete Permission"
                  variant="danger"
                  button
                  class="local-permission-actions cursor-pointer ml-1 mb-1"
                  @click="showDeleteModal(permission, data.item.slug)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                </b-avatar>

                <!-- Add Local Permissions -->
                <b-avatar
                  v-b-tooltip.hover
                  title="Add New Permission"
                  variant="success"
                  button
                  class="add-local-permission ml-1 mb-1"
                  @click="showModal(data.item)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                  />
                </b-avatar>
              </div>

            </div>
          </div>
          <div
            v-else
            :class="canManagePermissions ? 'local-permission' : ''"
          >
            <span class="no-permission">-</span>
            <!-- Add Local Permissions -->
            <b-avatar
              v-if="canManagePermissions"
              v-b-tooltip.hover
              title="Add New Permission"
              variant="success"
              button
              class="add-local-permission ml-1"
              @click="showModal(data.item)"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
            </b-avatar>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="permissions.total > perPage"
        v-model="currentPage"
        :total-rows="permissions.total"
        :per-page="perPage"
        :disabled="isLoading"
        class="float-right pb-2"
        aria-controls="permission-table"
        @input="() => { isLoading = true; getPermissions() }"
      />
    </b-overlay>

    <create-edit-permissions
      :permission-info="permissionInfo"
      :permission="selectedPermission"
      @updated="() => { isLoading = true; onUpdated() }"
      @close="() => selectedPermission = null"
    />

    <!-- Delete Modal -->
    <b-modal
      id="delete-permission-modal"
      title="Delete Permission"
      ok-title="Delete"
      ok-variant="danger"
      @ok="deletePermission"
    >
      <p v-if="permissionToDelete">
        Are you sure you want to delete <span class="font-weight-bold">{{ permissionToDelete.slug }}</span> permission?
      </p>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import useApollo from '@/plugins/graphql/useApollo'
import {
  BTable, BAvatar, BOverlay, BPagination, VBTooltip,
} from 'bootstrap-vue'
import CreateEditPermissions from './CreateEditPermissions.vue'

export default {
  components: {
    BOverlay,
    BTable,
    BAvatar,
    BPagination,
    CreateEditPermissions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    canManagePermissions: {
      type: Boolean,
      default: () => false,
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      permissions: {},
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'slug', label: this.$t('permissions.permission'), thClass: 'text-capitalize',
        },
        { key: 'globalPermissions', label: this.$t('permissions.global-permission'), thClass: 'text-capitalize' },
        { key: 'localPermissions', label: this.$t('permissions.local-permission'), thClass: 'text-capitalize' },
      ],
      selectedPermission: null,
      permissionToDelete: null,
      permissionInfo: {},
      isLoading: true,
    }
  },
  watch: {
    searchTerm: {
      handler(val) {
        if (val || val === '') this.getPermissions()
      },
      immediate: true,
    },
  },
  mounted() {
    this.getPermissions()
  },
  methods: {
    onUpdated() {
      this.$emit('updated')
      this.getPermissions()
    },
    getPermissions: _.debounce(function () {
      this.isLoading = true
      useApollo.project.getAllPermissionsByProject({
        slug: this.searchTerm,
        projectUid: this.$store.state.project.selectedProject,
        first: this.perPage,
        page: this.currentPage,
      }).then(response => {
        this.permissions = response.data.permissions
      }).finally(() => { this.isLoading = false })
    }, 1000),
    showModal(item, permission = null) {
      if (permission) this.selectedPermission = permission
      this.permissionInfo = {
        slug: item.slug,
        description: item.description,
      }
      this.$bvModal.show('create-edit-permission')
    },
    showDeleteModal(permission, slug) {
      this.permissionToDelete = permission
      this.permissionToDelete.slug = slug
      this.$bvModal.show('delete-permission-modal')
    },
    deletePermission() {
      this.isLoading = true
      const data = {
        projectUid: this.permissionToDelete.permitted.__typename === 'Project' ? this.permissionToDelete.permitted.uuid : '',
        companyUid: this.$store.state.project.selectedCompany,
        slug: this.permissionToDelete.slug,
        input: {
          action: 'DELETE',
        },
      }

      if (this.permissionToDelete.permissible?.__typename === 'User') data.input.userUid = this.permissionToDelete.permissible.uuid
      else data.input.role = this.permissionToDelete.permissible.name
      if (this.permissionToDelete.permitted.__typename === 'Project') {
        useApollo.project.updateProjectPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateProjectPermission.message,
            },
          })
        }).finally(() => { this.getPermissions() })
      } else if (this.permissionToDelete.permitted.__typename === 'Company') {
        useApollo.project.updateCompanyPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateCompanyPermission.message,
            },
          })
        }).finally(() => {
          this.getPermissions()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .add-local-permission,
  .local-permission-actions {
    opacity: 0;
  }

  .local-permission:hover {
    .no-permission {
      display: none;
    }
    .add-local-permission,
    .local-permission-actions{
      opacity: 1;
    }
  }
  .col-240 {
    min-width: 240px;
  }
  @media screen and (max-width: 768px) {
    .add-local-permission,
    .local-permission-actions {
      opacity: 1;
    }
  }
</style>
